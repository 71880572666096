import { MA_APP_IDS, withMembersArea } from '@wix/members-area-integration-kit';
import * as application from './platform';

const platformApp = withMembersArea(application, {
  installAutomatically: false,
  installAppsAutomatically: false,
  membersAreaApps: [
    MA_APP_IDS.ABOUT,
    MA_APP_IDS.SETTINGS,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.ALL_MEMBERS,
  ],
});

export const editorReady = platformApp.editorReady;
export const getAppManifest = platformApp.getAppManifest;
export const exports = platformApp.exports;
